<!-- 从default.vue和activity.vue中修改而来，由于原有的default layout中没有使用border-box，导致 base-default-layout-content容器实际高度高于实际viewport高度-->
<!-- 本layout修复了这一问题， 并添加添加一系列reset css -->

<!-- 可通过meta.headerDisable控制是否显示h5页面自己的头部导航栏, true: 使用app原生头部导航栏 -->

<template>
  <div :style="{ height: deviceHeight }">
    <slot v-if="!route.meta.headerDisable" name="header">
      <!-- 在wechat和qq环境中，自动隐藏顶部导航栏 -->
      <BaseHeader v-if="!isInWechat() && !isInQQApp()" />
    </slot>
    <slot name="main">
      <div
        class="base-default-layout-content overflow-y-auto h-full"
        :style="{
          paddingTop: layoutTop
        }"
      >
        <slot />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import Cookies from 'js-cookie'
import { getVantNavBarHeight, disableZoom } from '@/utils/index'
import { useSharedStore } from '~/stores/shared'
import { isInChrome, isiOSVersionAbove15 } from '~/utils/device'

const sharedStore = useSharedStore()
const route = useRoute()

const layoutTop = computed(() => {
  if (isInWechat() || isInQQApp() || route.meta.headerDisable) {
    return '0px'
  } else {
    return `${px2rem(46) + px2rem(sharedStore?.C5ToolBarHeight || 0)}rem`
  }
})

const deviceHeight = ref('100vh')

watch(deviceHeight, value => {
  console.log(`高度： ${value}`)
})

onMounted(async () => {
  await nextTick()

  const ua = navigator.userAgent

  console.log(`isiPhoneSafari：${isiPhoneSafari()}`)
  console.log(`isMobile：${isMobile()}`)
  console.log(`isInChrome：${isInChrome()}`)
  console.log(`isiOSVersionAbove15: ${isiOSVersionAbove15()}`)

  sharedStore.setHeaderHeight(getVantNavBarHeight())
  sharedStore.setC5ToolBarHeight(Number(Cookies.get('C5ToolBarHeight') || 0))

  if (!route.meta.headerDisable) {
    native?.hiddenToolBar?.()
  }
  disableZoom()

  if (isInApp) {
    deviceHeight.value = '100vh'
    return
  }

  // safari
  if (isiPhoneSafari()) {
    // ios15 及以上
    if (isiOSVersionAbove15()) {
      deviceHeight.value = '89vh'
    } else {
      deviceHeight.value = '85vh'
    }
  } else if (isMobile() && isAndroidDevice() && !isInQQ() && !isInWechat()) {
    // 安卓浏览器
    if (/HeyTapBrowser/i.test(ua)) {
      // 欢太浏览器 - oppo
      deviceHeight.value = `${px2rem(window.document.documentElement.clientHeight)}rem`
      return
    }
    if (/VivoBrowser/i.test(ua)) {
      // vivo
      deviceHeight.value = 'calc(100vh - 50px)'
      return
    }
    if (/MiuiBrowser/i.test(ua)) {
      // 小米
      deviceHeight.value = `${px2rem(window.document.documentElement.clientHeight - 55)}rem`

      // innerHeight基准 745
      // innerHeight差值 = window.innerHeight - 745
      // 需要减去的高度 = -35 + window.innerHeight - 745
      console.log(
        `window.screen.height: ${window.screen.height}; window.innerHeight: ${window.innerHeight}; window.document.documentElement.clientHeight: ${window.document.documentElement.clientHeight}`
      )
      return
    }
    deviceHeight.value = 'calc(100vh - 50px)'
  } else {
    // pc端 || 第三方app
    deviceHeight.value = '100vh'
  }
})
</script>

<style lang="scss" scoped>
.base-default-layout-content {
  /* resets start */
  box-sizing: border-box;
  *,
  *::before,
  *::after,
  :deep(*, *::before, *::after) {
    box-sizing: border-box;
  }

  :deep(img) {
    display: block;
  }
  /* resets end */

  color: theme('colors.grey.153');

  --van-cell-background: #fff;
  --van-cell-value-font-size: 15px;
  --van-cell-text-color: theme('colors.grey.153');
  --van-cell-value-color: theme('colors.grey.153');
  --van-cell-vertical-padding: 15px;
  --van-cell-horizontal-padding: 15px;
  --van-cell-border-color: theme('colors.grey.702');
  --van-cell-right-icon-color: theme('colors.grey.612');
}
</style>
